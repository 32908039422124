/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Footer from "../components/footer"
import SEO from "../components/seo"
import Layout from "../components/layout"

const News = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="News | tinyML Foundation"
        description="TinyML Summit. The topic is advances in ultra-low power Machine Learning technologies and applications."
      />
      <div className="grid-container gap-xl posts" sx={{ paddingTop: `170px` }}>
        <h1>News</h1>
        <div className="grid-x grid-margin-x grid-margin-y">
          {data.allWpPost.nodes.map(e => {
            return (
              <div key={e.id} className="large-4 medium-12 small-12 cell">
                <div className="posts__img_holder">
                  <Link to={e.slug}>
                    <Img
                      fluid={
                        e?.postLink?.postImage?.localFile?.childImageSharp
                          ?.fluid
                      }
                      alt={e.title}
                    />
                  </Link>
                  <Link to={e.slug}>
                    <span className="news-button">Read more</span>
                  </Link>
                </div>
                {/*{e.categories.nodes.map(c => {
                  return (
                    <div key={c.id} className="posts-categories">
                      <span>{c.name}</span>
                    </div>
                  )
                })}
              <br />
                  <span className="news-category">{e.date}</span>*/}
                <Link to={e.slug}>
                  <h3>{e.title}</h3>
                </Link>

                <div
                  dangerouslySetInnerHTML={{
                    __html: e?.postLink?.postExcerpt,
                  }}
                ></div>
              </div>
            )
          })}
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpMenu {
      nodes {
        menuItems {
          nodes {
            childItems {
              nodes {
                label
                url
              }
            }
            label
            url
          }
        }
      }
    }
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          label
          childItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        excerpt
        postLink {
          postImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          postContent
          postExcerpt
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        title
        categories {
          nodes {
            id
            name
          }
        }
        date(formatString: "MMMM DD, YYYY")
        content
        slug
      }
    }
  }
`

export default News
